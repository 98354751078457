export default async (apis, detector) => {
    // get infos on the device on which code is executed
    const infos = await apis.platform.getInfo();
    const isMouse = infos.input === "mouse";
    const isKeyboard = infos.input === "remote";
    // Layout class
    detector.update("tv-screen", infos.isTVScreen);
    detector.update("touch_portrait", infos.isPhone);
    detector.update("touch_landscape", infos.isTablet);
    // Focus class
    // TODO: remove all references to use-focus class from all css rules, then remove the next line
    detector.update("use-focus", true); // isKeyboard);

    // input class
    detector.update("mouse", isMouse);
    detector.update("keyboard", isKeyboard);
    detector.update("touch", infos.isTouch);

    // the environment is set prefixed by namespace "env-" to permit target specific deviceType
    const {deviceType} = infos;
    if (deviceType) {
        detector.update(`env-${deviceType}`, true);
    }
};
